exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-bach-flowers-js": () => import("./../../../src/pages/bach-flowers.js" /* webpackChunkName: "component---src-pages-bach-flowers-js" */),
  "component---src-pages-consultations-js": () => import("./../../../src/pages/consultations.js" /* webpackChunkName: "component---src-pages-consultations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-brand-js": () => import("./../../../src/pages/our-brand.js" /* webpackChunkName: "component---src-pages-our-brand-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-products-array-js": () => import("./../../../src/templates/products-array.js" /* webpackChunkName: "component---src-templates-products-array-js" */)
}

